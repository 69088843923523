import { emptySplitApi as api } from "./emptyApi";
export const addTagTypes = [
  "postCartByGift",
  "patchCartByGift",
  "getCartByGift",
  "createIncompleteOrderByGiftAndCertificate",
  "updateIncompleteOrderByGiftAndCertificate",
  "confirmOrderByGiftAndCertificate",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      postCartByGift: build.mutation<
        PostCartByGiftApiResponse,
        PostCartByGiftApiArg
      >({
        query: (queryArg) => ({
          url: `/gift/cart/${queryArg.productId}/${queryArg.quantity}`,
          method: "POST",
          data: queryArg.map,
        }),
        invalidatesTags: ["postCartByGift"],
      }),
      patchCartByGift: build.mutation<
        PatchCartByGiftApiResponse,
        PatchCartByGiftApiArg
      >({
        query: (queryArg) => ({
          url: `/gift/cart/${queryArg.cartItemId}/${queryArg.quantity}`,
          method: "PATCH",
          data: queryArg.map,
        }),
        invalidatesTags: ["patchCartByGift"],
      }),
      getCartByGift: build.query<GetCartByGiftApiResponse, GetCartByGiftApiArg>(
        {
          query: () => ({ url: `/gift/cart` }),
          providesTags: ["getCartByGift"],
        },
      ),
      createIncompleteOrderByGiftAndCertificate: build.mutation<
        CreateIncompleteOrderByGiftAndCertificateApiResponse,
        CreateIncompleteOrderByGiftAndCertificateApiArg
      >({
        query: (queryArg) => ({
          url: `/gift/incomplete`,
          method: "POST",
          data: queryArg.createIncompleteOrderRequest,
        }),
        invalidatesTags: ["createIncompleteOrderByGiftAndCertificate"],
      }),
      updateIncompleteOrderByGiftAndCertificate: build.mutation<
        UpdateIncompleteOrderByGiftAndCertificateApiResponse,
        UpdateIncompleteOrderByGiftAndCertificateApiArg
      >({
        query: (queryArg) => ({
          url: `/gift/incomplete/${queryArg.id}`,
          method: "PUT",
          data: queryArg.updateIncompleteOrderRequest,
        }),
        invalidatesTags: ["updateIncompleteOrderByGiftAndCertificate"],
      }),
      confirmOrderByGiftAndCertificate: build.mutation<
        ConfirmOrderByGiftAndCertificateApiResponse,
        ConfirmOrderByGiftAndCertificateApiArg
      >({
        query: (queryArg) => ({
          url: `/gift/confirm/${queryArg.id}`,
          method: "POST",
          data: queryArg.confirmOrderRequest,
        }),
        invalidatesTags: ["confirmOrderByGiftAndCertificate"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as edgeGiftApi };
export type PostCartByGiftApiResponse = /** status 200  */ CartResponse;
export type PostCartByGiftApiArg = {
  productId: string;
  /** Quantity */
  quantity: number;
  map: Map;
};
export type PatchCartByGiftApiResponse = /** status 200  */ CartResponse;
export type PatchCartByGiftApiArg = {
  cartItemId: string;
  /** Quantity */
  quantity: number;
  map: Map;
};
export type GetCartByGiftApiResponse = /** status 200  */ CartResponse;
export type GetCartByGiftApiArg = void;
export type CreateIncompleteOrderByGiftAndCertificateApiResponse =
  /** status 200  */ IncompleteOrderResponse;
export type CreateIncompleteOrderByGiftAndCertificateApiArg = {
  createIncompleteOrderRequest: CreateIncompleteOrderRequest;
};
export type UpdateIncompleteOrderByGiftAndCertificateApiResponse =
  /** status 200  */ IncompleteOrderResponse;
export type UpdateIncompleteOrderByGiftAndCertificateApiArg = {
  id: string;
  updateIncompleteOrderRequest: UpdateIncompleteOrderRequest;
};
export type ConfirmOrderByGiftAndCertificateApiResponse =
  /** status 200  */ ConfirmOrderResponse;
export type ConfirmOrderByGiftAndCertificateApiArg = {
  id: string;
  confirmOrderRequest: ConfirmOrderRequest;
};
export type CartItem = {
  id: string;
  productId?: string;
  orderId?: string;
  orderNumber?: string;
  quantity?: number;
  meta?: object;
};
export type Cart = {
  id: string;
  items: CartItem[];
};
export type CartResponse = {
  code?: number;
  messages?: string[];
  result?: Cart;
};
export type VoidResponse = {
  code?: number;
  messages?: string[];
};
export type Map = {};
export type ProfileDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  gender?: string;
  dateOfBirth?: string;
  email?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  fullName: string;
};
export type CustomerDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  profileId: string;
  isGuest: boolean;
  phoneNumber1?: string;
  phoneNumber2?: string;
  phoneNumber3?: string;
  membershipNumber?: string;
  membershipType?: number;
  membershipValidFrom?: string;
  membershipValidUntil?: string;
  isOptOutMarketing: boolean;
  isOptOutReminding?: number;
  restrictionType?: number;
  vaultCode?: string;
  legacyId?: string;
  status: number;
  Profile?: ProfileDto;
};
export type Rates = {
  id?: string;
  name?: string;
  taxCode?: string;
  rate: number;
  type: number;
  tipAmount?: number;
  discountAmount?: number;
};
export type OrderLineGiftCertificateMeta = {
  id: string;
  name: string;
  discountAmount: number;
  tipAmount: number;
  isCommission: boolean;
  capacity: number;
};
export type OrderType = 0 | 1 | 2 | 3 | 99;
export type OrderLineMeta = {
  rates?: Rates[];
  giftCertificate?: OrderLineGiftCertificateMeta;
  orderType?: OrderType;
};
export type CustomerAddresses = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  customerId: string;
  type: number;
  recipientName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  status: number;
};
export type OrderShippingDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  type: number;
  addressId?: string;
  trackingNumber?: string;
  shippingDate?: string;
  senderName?: string;
  senderEmail?: string;
  recipientName?: string;
  recipientEmail?: string;
  pickupFullName?: string;
  pickupDateTime?: string;
  pickupPhone?: string;
  isPickupAgreeTerms?: boolean;
  personalMessage?: string;
  requestMessage?: string;
  status: number;
  CustomerAddress?: CustomerAddresses;
};
export type ProductMeta = {
  templateName?: string;
  expiration?: number;
  followListingPrice?: boolean;
  onlinePriceMethod?: number;
};
export type Product = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  categoryId?: string;
  name: string;
  description?: string;
  gtin?: string;
  brand?: string;
  mpn?: string;
  price: number;
  taxId1?: string;
  taxId2?: string;
  taxId3?: string;
  tipId?: string;
  isCommissionOnRetailSales: boolean;
  isTaxDefault: boolean;
  isCommissionOnRetailSalesDefault: boolean;
  isPrepaidOnly?: boolean;
  tags?: string;
  meta?: ProductMeta;
  status: number;
};
export type Ticket = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  reservationId: string;
  orderLineId?: string;
  status: number;
  refs?: string;
};
export type OrderLinesDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  orderId: string;
  sequence?: number;
  categoryId: string;
  productId: string;
  customerId?: string;
  orderShippingId?: string;
  appointmentId?: string;
  salesRepresentativeId?: string;
  listPrice: number;
  sellingPrice: number;
  tax1Amount: number;
  tax2Amount: number;
  extraChargeAmount: number;
  tip1Amount: number;
  tip2Amount: number;
  discount1Amount: number;
  discount2Amount: number;
  discount3Amount: number;
  promotionAmount: number;
  totalAmount: number;
  refundAmount: number;
  status: number;
  orderLineId?: string;
  description?: string;
  meta?: OrderLineMeta;
  productType: number;
  productName?: string;
  endOfDateId?: string;
  OrderShipping?: OrderShippingDto;
  Product?: Product;
  Ticket?: Ticket;
};
export type Timeslot = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  dateslotId: string;
  time: string;
  capacity: number;
  status: number;
};
export type Reservation = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  productId: string;
  dateslotId: string;
  timeslotId: string;
  numberOfGuest: number;
  orderId?: string;
  status: number;
  refs?: string;
  Timeslot?: Timeslot;
};
export type PaymentLineMetaResponseDataChargePaymentMethodTransactionRiskData =
  {
    id?: string;
    decision?: string;
  };
export type PaymentLineMetaResponseDataChargePaymentMethodTransaction = {
  status?: string;
  riskData?: PaymentLineMetaResponseDataChargePaymentMethodTransactionRiskData;
};
export type PaymentLineMetaResponseDataChargePaymentMethod = {
  transaction?: PaymentLineMetaResponseDataChargePaymentMethodTransaction;
};
export type PaymentLineMetaResponseDataReverseTransactionReversal = {
  id?: string;
  status?: string;
};
export type PaymentLineMetaResponseDataReverseTransaction = {
  reversal?: PaymentLineMetaResponseDataReverseTransactionReversal;
};
export type PaymentLineMetaResponseData = {
  chargePaymentMethod?: PaymentLineMetaResponseDataChargePaymentMethod;
  reverseTransaction?: PaymentLineMetaResponseDataReverseTransaction;
};
export type PaymentLineMetaResponse = {
  data?: PaymentLineMetaResponseData;
};
export type PaymentLineMeta = {
  response?: PaymentLineMetaResponse;
};
export type PaymentLines = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  orderId: string;
  type: number;
  tenderType: number;
  label?: string;
  grossAmount: number;
  adjustmentAmount: number;
  tipAmount: number;
  taxAmount: number;
  requestAmount: number;
  uniqueCode?: string;
  approvedAmount: number;
  customerId?: string;
  reason?: string;
  relationId?: string;
  status: number;
  cardHolderName?: string;
  billingAddress?: string;
  paymentLineId?: string;
  description?: string;
  meta?: PaymentLineMeta;
  customerName?: string;
};
export type Channel = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  orderNumber: string;
  paymentNumber?: string;
  legacyNumber?: string;
};
export type IncompleteOrderDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  channelId?: string;
  endOfDate?: string;
  orderNumber: string;
  status: number;
  deviceId: string;
  cashierId: string;
  cashierName: string;
  customerId?: string;
  receiptEmail?: string;
  amount: number;
  tax1Amount: number;
  tax2Amount: number;
  extraChargeAmount: number;
  tip1Amount: number;
  tip2Amount: number;
  discount1Amount: number;
  discount2Amount: number;
  discount3Amount: number;
  shippingAmount: number;
  totalAmount: number;
  paidAmount: number;
  refundAmount: number;
  refundShippingAmount: number;
  orderId?: string;
  ipAddress?: string;
  description?: string;
  completedAt?: string;
  refundedAt?: string;
  meta?: object;
  updatedByName: string;
  lineCount?: number;
  lockerNumbers?: string;
  tenderCount?: number;
  tenders?: string;
  Customer?: CustomerDto;
  OrderLines?: OrderLinesDto[];
  Reservation?: Reservation;
  PaymentLines?: PaymentLines[];
  Channel?: Channel;
  dueAtServiceAmount?: number;
  dueNowAmount?: number;
  restrictionType?: number;
};
export type IncompleteOrderResponse = {
  code?: number;
  messages?: string[];
  result?: IncompleteOrderDto;
};
export type RemindingType = 0 | 1 | 2 | 3;
export type CreateIncompleteOrderRequestContact = {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isOptOutMarketing?: boolean;
  isOptOutReminding?: RemindingType;
};
export type DeliveryMethod = 1 | 2 | 3;
export type CreateIncompleteOrderRequestDeliveryShippingAddress = {
  city: string;
  state: string;
  zipcode: string;
  address: string;
};
export type CreateIncompleteOrderRequestDeliveryPickup = {
  fullName: string;
  phone: string;
  dateTime: string;
};
export type CreateIncompleteOrderRequestDelivery = {
  deliveryMethod: DeliveryMethod;
  emailTo: string;
  shippingAddress?: CreateIncompleteOrderRequestDeliveryShippingAddress;
  pickup?: CreateIncompleteOrderRequestDeliveryPickup;
  nameFrom: string;
  nameTo: string;
  shippingFullName?: string;
  personalMessage?: string;
};
export type CreateIncompleteOrderRequest = {
  customer?: CreateIncompleteOrderRequestContact;
  delivery?: CreateIncompleteOrderRequestDelivery;
  orderType?: OrderType;
  cartItemIds: string[];
};
export type UpdateIncompleteOrderRequest = {
  customer: CreateIncompleteOrderRequestContact;
  delivery: CreateIncompleteOrderRequestDelivery;
  orderType?: OrderType;
};
export type ConfirmOrderDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  channelId?: string;
  endOfDate?: string;
  orderNumber: string;
  status: number;
  deviceId: string;
  cashierId: string;
  cashierName: string;
  customerId?: string;
  receiptEmail?: string;
  amount: number;
  tax1Amount: number;
  tax2Amount: number;
  extraChargeAmount: number;
  tip1Amount: number;
  tip2Amount: number;
  discount1Amount: number;
  discount2Amount: number;
  discount3Amount: number;
  shippingAmount: number;
  totalAmount: number;
  paidAmount: number;
  refundAmount: number;
  refundShippingAmount: number;
  orderId?: string;
  ipAddress?: string;
  description?: string;
  completedAt?: string;
  refundedAt?: string;
  meta?: object;
  updatedByName: string;
  lineCount?: number;
  lockerNumbers?: string;
  tenderCount?: number;
  tenders?: string;
  Customer?: CustomerDto;
  OrderLines?: OrderLinesDto[];
  Reservation?: Reservation;
  PaymentLines?: PaymentLines[];
  Channel?: Channel;
  dueAtServiceAmount?: number;
  dueNowAmount?: number;
  restrictionType?: number;
};
export type ConfirmOrderResponse = {
  code?: number;
  messages?: string[];
  result?: ConfirmOrderDto;
};
export type ConfirmOrderRequestBillingAddress = {
  city: string;
  state: string;
  zipcode: string;
  address: string;
};
export type TenderType = 0 | 1 | 2 | 3 | 4 | 11 | 12 | 13 | 14 | 15 | 16;
export type ConfirmOrderRequestPayment = {
  billingAddress?: ConfirmOrderRequestBillingAddress;
  tenderType: TenderType;
  deviceData?: string | null;
  /** 기존의 텐더를 사용할 때 PaymentLineId를 UniqueCode로 사용. */
  uniqueCode: string;
  isSkip?: boolean;
};
export type ConfirmOrderRequest = {
  payment: ConfirmOrderRequestPayment;
  orderType?: OrderType;
  promotionCode?: string;
};
export const {
  usePostCartByGiftMutation,
  usePatchCartByGiftMutation,
  useGetCartByGiftQuery,
  useCreateIncompleteOrderByGiftAndCertificateMutation,
  useUpdateIncompleteOrderByGiftAndCertificateMutation,
  useConfirmOrderByGiftAndCertificateMutation,
} = injectedRtkApi;
