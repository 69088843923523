import { emptySplitApi as api } from "./emptyApi";
export const addTagTypes = [
  "getList",
  "getOrderByIdOrOrderNumber",
  "deleteOrderById",
  "cancelOrder",
  "getHistory",
  "postHistory",
  "resendOrder",
  "onlineOrderResend",
  "refundOrder",
  "getLock",
  "deleteKick",
  "getPromotion",
  "getAddedValuePromotion",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getList: build.query<GetListApiResponse, GetListApiArg>({
        query: (queryArg) => ({
          url: `/order/list`,
          params: {
            pageName: queryArg.pageName,
            keyword: queryArg.keyword,
            dateFrom: queryArg.dateFrom,
            dateTo: queryArg.dateTo,
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            dateBy: queryArg.dateBy,
            orderStatus: queryArg.orderStatus,
          },
        }),
        providesTags: ["getList"],
      }),
      getOrderByIdOrOrderNumber: build.query<
        GetOrderByIdOrOrderNumberApiResponse,
        GetOrderByIdOrOrderNumberApiArg
      >({
        query: (queryArg) => ({ url: `/order/${queryArg.idOrOrderNumber}` }),
        providesTags: ["getOrderByIdOrOrderNumber"],
      }),
      deleteOrderById: build.mutation<
        DeleteOrderByIdApiResponse,
        DeleteOrderByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/order/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["deleteOrderById"],
      }),
      cancelOrder: build.mutation<CancelOrderApiResponse, CancelOrderApiArg>({
        query: (queryArg) => ({
          url: `/order/${queryArg.id}/cancel`,
          method: "PATCH",
        }),
        invalidatesTags: ["cancelOrder"],
      }),
      getHistory: build.query<GetHistoryApiResponse, GetHistoryApiArg>({
        query: (queryArg) => ({ url: `/order/${queryArg.id}/history` }),
        providesTags: ["getHistory"],
      }),
      postHistory: build.mutation<PostHistoryApiResponse, PostHistoryApiArg>({
        query: (queryArg) => ({
          url: `/order/${queryArg.id}/history`,
          method: "POST",
          data: queryArg.createHistory,
        }),
        invalidatesTags: ["postHistory"],
      }),
      resendOrder: build.mutation<ResendOrderApiResponse, ResendOrderApiArg>({
        query: (queryArg) => ({
          url: `/order/${queryArg.id}/resend`,
          method: "POST",
        }),
        invalidatesTags: ["resendOrder"],
      }),
      onlineOrderResend: build.mutation<
        OnlineOrderResendApiResponse,
        OnlineOrderResendApiArg
      >({
        query: (queryArg) => ({
          url: `/order/${queryArg.id}/resend`,
          method: "PATCH",
          data: queryArg.resendRequest,
        }),
        invalidatesTags: ["onlineOrderResend"],
      }),
      refundOrder: build.mutation<RefundOrderApiResponse, RefundOrderApiArg>({
        query: (queryArg) => ({
          url: `/order/${queryArg.id}/refund`,
          method: "PATCH",
          data: queryArg.reservationPatchRequest,
        }),
        invalidatesTags: ["refundOrder"],
      }),
      getLock: build.query<GetLockApiResponse, GetLockApiArg>({
        query: (queryArg) => ({ url: `/order/${queryArg.id}/lock` }),
        providesTags: ["getLock"],
      }),
      deleteKick: build.mutation<DeleteKickApiResponse, DeleteKickApiArg>({
        query: (queryArg) => ({
          url: `/order/${queryArg.id}/kick`,
          method: "DELETE",
        }),
        invalidatesTags: ["deleteKick"],
      }),
      getPromotion: build.query<GetPromotionApiResponse, GetPromotionApiArg>({
        query: (queryArg) => ({ url: `/order/promotion/${queryArg.name}` }),
        providesTags: ["getPromotion"],
      }),
      getAddedValuePromotion: build.query<
        GetAddedValuePromotionApiResponse,
        GetAddedValuePromotionApiArg
      >({
        query: () => ({ url: `/order/addedValuePromotion` }),
        providesTags: ["getAddedValuePromotion"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as edgeOrderApi };
export type GetListApiResponse = /** status 200  */ OrderListResponse;
export type GetListApiArg = {
  pageName?: string;
  keyword?: string;
  dateFrom?: string;
  dateTo?: string;
  page?: number;
  pageSize?: number;
  dateBy?: number;
  orderStatus?: number;
};
export type GetOrderByIdOrOrderNumberApiResponse =
  /** status 200  */ OrderResponse;
export type GetOrderByIdOrOrderNumberApiArg = {
  idOrOrderNumber: string;
};
export type DeleteOrderByIdApiResponse = unknown;
export type DeleteOrderByIdApiArg = {
  id: string;
};
export type CancelOrderApiResponse = /** status 200  */ OrderResponse;
export type CancelOrderApiArg = {
  /** Order Id */
  id: string;
};
export type GetHistoryApiResponse = /** status 200  */ OrderHistoryResponse;
export type GetHistoryApiArg = {
  id: string;
};
export type PostHistoryApiResponse = /** status 200  */ CreatedHistoryResponse;
export type PostHistoryApiArg = {
  /** Order Id */
  id: string;
  createHistory: CreateHistory;
};
export type ResendOrderApiResponse = /** status 200  */ OrderResponse;
export type ResendOrderApiArg = {
  /** Order Id */
  id: string;
};
export type OnlineOrderResendApiResponse = /** status 200  */ VoidResponse;
export type OnlineOrderResendApiArg = {
  /** Order Id */
  id: string;
  resendRequest: ResendRequest;
};
export type RefundOrderApiResponse = /** status 200  */ OrderResponse;
export type RefundOrderApiArg = {
  /** Order Id */
  id: string;
  reservationPatchRequest: ReservationPatchRequest;
};
export type GetLockApiResponse = unknown;
export type GetLockApiArg = {
  id: string;
};
export type DeleteKickApiResponse = unknown;
export type DeleteKickApiArg = {
  id: string;
};
export type GetPromotionApiResponse = /** status 200  */ boolean;
export type GetPromotionApiArg = {
  name: string;
};
export type GetAddedValuePromotionApiResponse =
  /** status 200  */ AddedValuePromotionResponse;
export type GetAddedValuePromotionApiArg = void;
export type ProfileDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  gender?: string;
  dateOfBirth?: string;
  email?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  fullName: string;
};
export type CustomerDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  profileId: string;
  isGuest: boolean;
  phoneNumber1?: string;
  phoneNumber2?: string;
  phoneNumber3?: string;
  membershipNumber?: string;
  membershipType?: number;
  membershipValidFrom?: string;
  membershipValidUntil?: string;
  isOptOutMarketing: boolean;
  isOptOutReminding?: number;
  restrictionType?: number;
  vaultCode?: string;
  legacyId?: string;
  status: number;
  Profile?: ProfileDto;
};
export type Rates = {
  id?: string;
  name?: string;
  taxCode?: string;
  rate: number;
  type: number;
  tipAmount?: number;
  discountAmount?: number;
};
export type OrderLineGiftCertificateMeta = {
  id: string;
  name: string;
  discountAmount: number;
  tipAmount: number;
  isCommission: boolean;
  capacity: number;
};
export type OrderType = 0 | 1 | 2 | 3 | 99;
export type OrderLineMeta = {
  rates?: Rates[];
  giftCertificate?: OrderLineGiftCertificateMeta;
  orderType?: OrderType;
};
export type CustomerAddresses = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  customerId: string;
  type: number;
  recipientName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  status: number;
};
export type OrderShippingDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  type: number;
  addressId?: string;
  trackingNumber?: string;
  shippingDate?: string;
  senderName?: string;
  senderEmail?: string;
  recipientName?: string;
  recipientEmail?: string;
  pickupFullName?: string;
  pickupDateTime?: string;
  pickupPhone?: string;
  isPickupAgreeTerms?: boolean;
  personalMessage?: string;
  requestMessage?: string;
  status: number;
  CustomerAddress?: CustomerAddresses;
};
export type ProductMeta = {
  templateName?: string;
  expiration?: number;
  followListingPrice?: boolean;
  onlinePriceMethod?: number;
};
export type Product = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  categoryId?: string;
  name: string;
  description?: string;
  gtin?: string;
  brand?: string;
  mpn?: string;
  price: number;
  taxId1?: string;
  taxId2?: string;
  taxId3?: string;
  tipId?: string;
  isCommissionOnRetailSales: boolean;
  isTaxDefault: boolean;
  isCommissionOnRetailSalesDefault: boolean;
  isPrepaidOnly?: boolean;
  tags?: string;
  meta?: ProductMeta;
  status: number;
};
export type Ticket = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  reservationId: string;
  orderLineId?: string;
  status: number;
  refs?: string;
};
export type OrderLinesDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  orderId: string;
  sequence?: number;
  categoryId: string;
  productId: string;
  customerId?: string;
  orderShippingId?: string;
  appointmentId?: string;
  salesRepresentativeId?: string;
  listPrice: number;
  sellingPrice: number;
  tax1Amount: number;
  tax2Amount: number;
  extraChargeAmount: number;
  tip1Amount: number;
  tip2Amount: number;
  discount1Amount: number;
  discount2Amount: number;
  discount3Amount: number;
  promotionAmount: number;
  totalAmount: number;
  refundAmount: number;
  status: number;
  orderLineId?: string;
  description?: string;
  meta?: OrderLineMeta;
  productType: number;
  productName?: string;
  endOfDateId?: string;
  OrderShipping?: OrderShippingDto;
  Product?: Product;
  Ticket?: Ticket;
};
export type Timeslot = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  dateslotId: string;
  time: string;
  capacity: number;
  status: number;
};
export type Reservation = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  productId: string;
  dateslotId: string;
  timeslotId: string;
  numberOfGuest: number;
  orderId?: string;
  status: number;
  refs?: string;
  Timeslot?: Timeslot;
};
export type PaymentLineMetaResponseDataChargePaymentMethodTransactionRiskData =
  {
    id?: string;
    decision?: string;
  };
export type PaymentLineMetaResponseDataChargePaymentMethodTransaction = {
  status?: string;
  riskData?: PaymentLineMetaResponseDataChargePaymentMethodTransactionRiskData;
};
export type PaymentLineMetaResponseDataChargePaymentMethod = {
  transaction?: PaymentLineMetaResponseDataChargePaymentMethodTransaction;
};
export type PaymentLineMetaResponseDataReverseTransactionReversal = {
  id?: string;
  status?: string;
};
export type PaymentLineMetaResponseDataReverseTransaction = {
  reversal?: PaymentLineMetaResponseDataReverseTransactionReversal;
};
export type PaymentLineMetaResponseData = {
  chargePaymentMethod?: PaymentLineMetaResponseDataChargePaymentMethod;
  reverseTransaction?: PaymentLineMetaResponseDataReverseTransaction;
};
export type PaymentLineMetaResponse = {
  data?: PaymentLineMetaResponseData;
};
export type PaymentLineMeta = {
  response?: PaymentLineMetaResponse;
};
export type PaymentLines = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  orderId: string;
  type: number;
  tenderType: number;
  label?: string;
  grossAmount: number;
  adjustmentAmount: number;
  tipAmount: number;
  taxAmount: number;
  requestAmount: number;
  uniqueCode?: string;
  approvedAmount: number;
  customerId?: string;
  reason?: string;
  relationId?: string;
  status: number;
  cardHolderName?: string;
  billingAddress?: string;
  paymentLineId?: string;
  description?: string;
  meta?: PaymentLineMeta;
  customerName?: string;
};
export type Channel = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  orderNumber: string;
  paymentNumber?: string;
  legacyNumber?: string;
};
export type OrderDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  channelId?: string;
  endOfDate?: string;
  orderNumber: string;
  status: number;
  deviceId: string;
  cashierId: string;
  cashierName: string;
  customerId?: string;
  receiptEmail?: string;
  amount: number;
  tax1Amount: number;
  tax2Amount: number;
  extraChargeAmount: number;
  tip1Amount: number;
  tip2Amount: number;
  discount1Amount: number;
  discount2Amount: number;
  discount3Amount: number;
  shippingAmount: number;
  totalAmount: number;
  paidAmount: number;
  refundAmount: number;
  refundShippingAmount: number;
  orderId?: string;
  ipAddress?: string;
  description?: string;
  completedAt?: string;
  refundedAt?: string;
  meta?: object;
  updatedByName: string;
  lineCount?: number;
  lockerNumbers?: string;
  tenderCount?: number;
  tenders?: string;
  Customer?: CustomerDto;
  OrderLines?: OrderLinesDto[];
  Reservation?: Reservation;
  PaymentLines?: PaymentLines[];
  Channel?: Channel;
  dueAtServiceAmount?: number;
  dueNowAmount?: number;
  restrictionType?: number;
};
export type OrderListResponse = {
  code?: number;
  messages?: string[];
  result?: OrderDto[];
  pageSize?: number;
  totalRecords?: number;
  totalPages?: number;
};
export type OrderResponse = {
  code?: number;
  messages?: string[];
  result?: OrderDto;
};
export type History = {
  id: string;
  createdBy?: string;
  relationId: string;
  level: number;
  message: string;
  meta?: object;
  createdAt: string;
};
export type OrderHistoryResponse = {
  code?: number;
  messages?: string[];
  result?: History[];
};
export type CreatedHistoryResponse = {
  code?: number;
  messages?: string[];
  result?: History;
};
export type CreateHistory = {
  message: string;
};
export type VoidResponse = {
  code?: number;
  messages?: string[];
};
export type ResendRequest = {
  receiptEmail: string;
  recipientName: string;
  recipientEmail: string;
  isOverride: boolean;
};
export type PatchOperation =
  | "add"
  | "replace"
  | "test"
  | "remove"
  | "move"
  | "copy";
export type Patch = {
  op: PatchOperation;
  /** Path */
  path: string;
  /** Value */
  value?: object;
  /** From */
  from?: string;
};
export type ReservationPatchRequest = {
  jsonPatch: Patch[];
  tender: string;
  reason?: string;
};
export type AddedValuePromotionResponse = {
  startDate: string;
  endDate: string;
  isPromotionOn: boolean;
  price1: number;
  addedValue1: number;
  price2: number;
  addedValue2: number;
  price3: number;
  addedValue3: number;
  isDigitalOnly: boolean;
};
export const {
  useGetListQuery,
  useGetOrderByIdOrOrderNumberQuery,
  useDeleteOrderByIdMutation,
  useCancelOrderMutation,
  useGetHistoryQuery,
  usePostHistoryMutation,
  useResendOrderMutation,
  useOnlineOrderResendMutation,
  useRefundOrderMutation,
  useGetLockQuery,
  useDeleteKickMutation,
  useGetPromotionQuery,
  useGetAddedValuePromotionQuery,
} = injectedRtkApi;
